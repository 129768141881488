import React from "react";
import { useFormik } from 'formik';
import { BsCheckCircleFill } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { caneN } from "../../assets/images";
import { useAuthStore } from "../../hooks/useAuthStore";


export const SignIn = () => {
  const navigate = useNavigate()

  const { startLogin } = useAuthStore()
  
  const validate = (values) => {
    const errors = {};
    if (!values.mail) {
      errors.mail = 'El campo Email es obligatorio';
    }
    if (!values.password) {
      errors.password = 'El campo Contraseña es obligatorio';
    }
  
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      mail: '',
      password: '',
    },
    validate,
    onSubmit: values => {
      loginSubmit(values)
    },
  });

  const loginSubmit = async (values) => {
    const { mail, password } = values
    startLogin({mail, password})
  }
  
  return (
    <div className="w-full h-screen flex items-center justify-center">
      <div className="w-1/2 hidden lgl:inline-flex h-full text-white">
        <div className="w-[450px] h-full bg-primeColor px-10 flex flex-col gap-6 justify-center">
          <Link to="/">
            <img src={caneN} alt="logoImg" className="w-16" />
          </Link>
          <div className="flex flex-col gap-1 -mt-1">
            <h1 className="font-titleFont text-xl font-medium">
              Permanece conectado para obtener más información
            </h1>
            <p className="text-base">Cuando inicias sesión, estás con nosotros</p>
          </div>
          <div className="w-[300px] flex items-start gap-3">
            <span className="text-black mt-1">
              <BsCheckCircleFill />
            </span>
            <p className="text-base text-gray-300">
              <span className="text-white font-semibold font-titleFont">
                Empieza rápidamente con CANE
              </span>
              <br />
              Descubre la forma más fácil de comenzar a disfrutar de nuestras prendas de moda y accesorios exclusivos. 
            </p>
          </div>
          <div className="w-[300px] flex items-start gap-3">
            <span className="text-black mt-1">
              <BsCheckCircleFill />
            </span>
            <p className="text-base text-gray-300">
              <span className="text-white font-semibold font-titleFont">
                Accede a todos los servicios de CANE
              </span>
              <br />
              En CANE, no solo encontrarás una amplia selección de ropa de alta calidad, sino que también tendrás acceso a una variedad de servicios exclusivos.
            </p>
          </div>
          <div className="w-[300px] flex items-start gap-3">
            <span className="text-black mt-1">
              <BsCheckCircleFill />
            </span>
            <p className="text-base text-gray-300">
              <span className="text-white font-semibold font-titleFont">
                Confiable para compradores en línea
              </span>
              <br />
              ¡Compra con tranquilidad y disfruta de la mejor moda en línea!
            </p>
          </div>
          <div className="flex items-center justify-between mt-10">
            <Link to="/">
              <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
                © CANE
              </p>
            </Link>
            <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
              Terms
            </p>
            <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
              Privacy
            </p>
            <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
              Security
            </p>
          </div>
        </div>
      </div>
      <div className="w-full lgl:w-1/2 h-full">
          <form onSubmit={formik.handleSubmit} className="w-full lgl:w-[450px] h-screen flex items-center justify-center">
            <div className="px-6 py-4 w-full h-[90%] flex flex-col justify-center overflow-y-scroll scrollbar-thin scrollbar-thumb-primeColor">
              <h1 className="font-titleFont underline underline-offset-4 decoration-[1px] font-semibold text-3xl mdl:text-4xl mb-4">
                Iniciar sesión
              </h1>
              <div className="flex flex-col gap-3">
                {/* Email */}
                <div className="flex flex-col gap-.5">
                  <p className="font-titleFont text-base font-semibold text-gray-600">
                    Email
                  </p>
                  <input
                    name="mail"
                    onChange={formik.handleChange}
                    value={formik.values.mail}
                    className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                    type="email"
                    placeholder="john@workemail.com"
                  />
                  {formik.errors.mail && formik.touched.mail && (
                    <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                      <span className="font-bold italic mr-1">!</span>
                      {formik.errors.mail}
                    </p>
                  )}
                </div>

                {/* Password */}
                <div className="flex flex-col gap-.5">
                  <p className="font-titleFont text-base font-semibold text-gray-600">
                    Contraseña
                  </p>
                  <input
                    name="password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                    type="password"
                    placeholder="Your password"
                  />
                  {formik.errors.password && formik.touched.password && (
                    <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                      <span className="font-bold italic mr-1">!</span>
                      {formik.errors.password}
                    </p>
                  )}
                </div>

                <button
                  type="submit"
                  className="bg-primeColor hover:bg-secondaryColor text-gray-200 hover:text-white cursor-pointer w-full text-base font-medium h-10 rounded-md  duration-300"
                >
                  Iniciar sesión
                </button>
                <p className="text-sm text-center font-titleFont font-medium">
                  ¿No tienes una cuenta?{" "}
                  <Link to="/signup">
                    <span className="hover:text-blue-600 duration-300">
                      Regístrate aquí
                    </span>
                  </Link>
                </p>
              </div>
            </div>
          </form>
      </div>
    </div>
  );
};
