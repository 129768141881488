import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useAuthStore } from "../hooks/useAuthStore";


export const PrivateRoute = ({ children }) => {
  const { status, checkAuthToken } = useAuthStore();

  useEffect(() => {
    checkAuthToken();
  }, []);

  console.log(status);

  if (status === "checking") {
    return <h2>Loading</h2>;
  }

  return status === "authenticated" ? (
    <>{children}</>
  ) : (
    <Navigate to="/signin" replace />
  );
};
