import React from 'react';
import Breadcrumbs from '../../components/pageProps/Breadcrumbs';
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { FaTimes } from "react-icons/fa";


export const PaymentError = () => {

    return (
        <div className="max-w-container mx-auto px-4">
            <Breadcrumbs title="Error de pago" />
            <motion.div
                initial={{ y: 30, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.4 }}
                className="flex flex-col mdl:flex-row justify-center items-center gap-4 pb-20"
            >
                <div className="rounded-full bg-white p-3 shadow-md">
                    <FaTimes size={60} color="#dd0b0b" />
                </div>
                <div className="max-w-[500px] p-4 py-8 bg-white flex gap-4 flex-col items-center rounded-md shadow-lg">
                    <h1 className="font-titleFont text-xl font-bold uppercase">
                        ¡Ha ocurrido un error!
                    </h1>
                    <p className="text-sm text-center px-10 -mt-2">
                        ¡Por favor, intente de nuevo más tarde!
                    </p>
                    <Link to="/shop">
                        <button className="bg-primeColor rounded-md cursor-pointer hover:bg-secondaryColor active:bg-gray-900 px-8 py-2 font-titleFont font-semibold text-lg text-gray-200 hover:text-white duration-300">
                            Ir a la tienda
                        </button>
                    </Link>
                </div>
            </motion.div>
        </div>
    );
};
