import { createSlice } from "@reduxjs/toolkit";

export const shopSlice = createSlice({
    name: "shop",
    initialState: {
        filters: {
            filterByPrice: "",
            filterByCategory: "",
        }
    },
    reducers: {
        setFilterByPrice: (state, action) => {
            state.filters.filterByPrice = action.payload
        },
        setFilterByCategory: (state, action) => {
            state.filters.filterByCategory = action.payload
        },
        cleanFilters: (state) => {
            state.filters.filterByPrice = ""
            state.filters.filterByCategory = ""
        }
    }
})

export const {
    setFilterByPrice, setFilterByCategory, cleanFilters
} = shopSlice.actions;