import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import Product from "../../home/Products/Product";
import { paginationItems } from "../../../constants";
import { caneApi, apiFiles } from '../../../api';
import { toast } from "react-toastify";
import { useShopStore } from "../../../hooks/useShopStore";

const items = paginationItems;
function Items({ currentItems }) {
  const [itemsToShow, setIitemsToShow] = useState(currentItems)
  const { filters } = useShopStore()

  useEffect(() => {
    if (filters.filterByPrice !== "") {
      const itemsFilteredByPrice = currentItems.filter((item) =>
        parseInt(item.precio) <= parseInt(filters.filterByPrice)
      )
      console.log(itemsFilteredByPrice);
      setIitemsToShow(itemsFilteredByPrice)
      return
    }
    if (filters.filterByCategory !== "" && filters.filterByCategory !== "Todos") {
      const itemsFilteredByCategory = currentItems.filter((item) =>
        item.cats.categoria === filters.filterByCategory
      )
      console.log(itemsFilteredByCategory);
      setIitemsToShow(itemsFilteredByCategory)
      return
    }
    setIitemsToShow(currentItems)

  }, [currentItems, filters.filterByPrice, filters.filterByCategory])


  return (
    <>
      {itemsToShow &&
        itemsToShow.map((item, i) => (
          <div key={i} className="w-full">
            <Product
              _id={item.id}
              id={item.id}
              img={`${apiFiles}/${item.id}/${item.files[0]}`}
              productName={item.nombre}
              price={item.precio}
              color={item.color}
              badge={item.badge}
              des={item.descipcion}
              files={item.files}
              nombre={item.nombre}
              descripcion={item.descripcion}
              precio={item.precio}
              tallas={item.inventario.tallas}
              precio_envio={item.precio_envio} />
          </div>
        ))}
    </>
  );
}

const Pagination = ({ itemsPerPage }) => {
  const [products, setProducts] = useState([])
  const getProducts = async () => {
    try {
      const { data } = await caneApi.get('/getProductos')
      setProducts(data.inventory)
      // toast.success("Productos cargados", {
      //   position: toast.POSITION.BOTTOM_RIGHT,
      //   autoClose: 3000,
      //   hideProgressBar: true,
      // })
    } catch (error) {
      console.log(error);
      toast.error("Error al obtener los productos", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
      })
    }
  }

  useEffect(() => {
    getProducts();
  }, [])

  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const [itemStart, setItemStart] = useState(1);


  const endOffset = itemOffset + itemsPerPage;
  //   console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = products.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
    setItemStart(newOffset);
  };

  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-10 mdl:gap-4 lg:gap-10">
        <Items currentItems={currentItems} />
      </div>
      <div className="flex flex-col mdl:flex-row justify-center mdl:justify-between items-center">
        <ReactPaginate
          nextLabel=""
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel=""
          pageLinkClassName="w-9 h-9 border-[1px] border-lightColor hover:border-gray-500 duration-300 flex justify-center items-center"
          pageClassName="mr-6"
          containerClassName="flex text-base font-semibold font-titleFont py-10"
          activeClassName="bg-black text-white"
        />

        <p className="text-base font-normal text-lightText">
          Products from {itemStart === 0 ? 1 : itemStart} to {endOffset} of{" "}
          {products.length}
        </p>
      </div>
    </div>
  );
};

export default Pagination;
