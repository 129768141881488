import { useDispatch, useSelector } from "react-redux";
import { setFilterByPrice, setFilterByCategory, cleanFilters } from "../redux/shop/shopSlice"

export const useShopStore = () => {
    const dispatch = useDispatch();

    const { filters } = useSelector((state) => state.shop);

    const handleSetFilterByPrice = (price) => {
        dispatch(cleanFilters())
        dispatch(setFilterByPrice(price))
    }

    const handleSetFilterByCategory = (category) => {
        dispatch(cleanFilters())
        dispatch(setFilterByCategory(category))
    }

    const handleCleanFilters = () => {
        dispatch(cleanFilters())
    }

    return {
        // Propiedades
        filters,
        // Métodos
        handleSetFilterByPrice,
        handleSetFilterByCategory,
        handleCleanFilters
    }
}