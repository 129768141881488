import { createSlice } from "@reduxjs/toolkit";

export const cartSlice = createSlice({
    name: "cart",
    initialState: {
        products: [],
        coupon_code: null,
    },
    reducers: {
        addToCart: (state, action) => {
            const item = state.products.find(
                (item) => item._id === action.payload._id && item.selectedSize === action.payload.selectedSize
            );
            if (item) {
                item.quantity += action.payload.quantity;
            } else {
                state.products.push(action.payload);
            }
        },
        increaseQuantity: (state, action) => {
            const item = state.products.find(
                (item) => item._id === action.payload._id
            );
            if (item) {
                item.quantity++;
            }
        },
        drecreaseQuantity: (state, action) => {
            const item = state.products.find(
                (item) => item._id === action.payload._id
            );
            if (item.quantity === 1) {
                item.quantity = 1;
            } else {
                item.quantity--;
            }
        },
        deleteItem: (state, action) => {
            state.products = state.products.filter(
                (item) => item._id !== action.payload
            );
        },
        resetCart: (state) => {
            state.products = [];
            state.coupon_code = null;
        },
        setCouponCode: (state, action) => {
            const coupon = action.payload;
            state.coupon_code = coupon;
        }
    }

})

export const {
    addToCart,
    increaseQuantity,
    drecreaseQuantity,
    deleteItem,
    resetCart,
    setCouponCode,
} = cartSlice.actions;