import { useDispatch, useSelector } from "react-redux";
import {
    addToCart,
    increaseQuantity,
    drecreaseQuantity,
    deleteItem,
    resetCart,
    setCouponCode
} from "../redux/cart/cartSlice"

export const useCartStore = () => {
    const dispatch = useDispatch();

    const { products } = useSelector((state) => state.cart);

    const { coupon_code } = useSelector((state) => state.cart);

    const addItemToCart = (product) => {
        dispatch(addToCart({ ...product, quantity: 1 }));
    }

    const increaseItemQuantity = (id) => {
        dispatch(increaseQuantity(id));
    }

    const decreaseItemQuantity = (id) => {
        dispatch(drecreaseQuantity(id));
    }

    const deleteItemToCart = (id) => {
        dispatch(deleteItem(id));
    }

    const resetCartStore = () => {
        dispatch(resetCart());
    }

    const applyCoupon = (code) => {
        dispatch(setCouponCode(code));
    }

    const totalPrice = products.reduce((acc, product) => {
        return acc + (product.price * product.quantity);
    }, 0)

    const totalPriceShipping = products.reduce((acc, product) => {
        const shippingPrice = parseFloat(product.precio_envio * product.quantity) || 0;
        return acc + shippingPrice;
    }, 0);

    return {
        // Propiedades
        products,
        coupon_code,
        // Métodos
        addItemToCart,
        increaseItemQuantity,
        decreaseItemQuantity,
        deleteItemToCart,
        resetCartStore,
        applyCoupon,
        totalPrice,
        totalPriceShipping
    }
}