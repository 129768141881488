import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Heading from "../Products/Heading";
import Product from "../Products/Product";
import SampleNextArrow from "./SampleNextArrow";
import SamplePrevArrow from "./SamplePrevArrow";
import { caneApi, apiFiles } from "../../../api";

const NewArrivals = () => {
  const [newArrivals, setNewArrivals] = useState([])

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  const getProducts = async () => {
    const { data } = await caneApi.get("/getProductosRecientes");
    setNewArrivals(data.inventory);
  }

  useEffect(() => {
    getProducts();
  }, [])


  return (
    <div className="w-full pb-16">
      <Heading heading="Recién llegados" />
      <Slider {...settings}>
        {
          newArrivals && newArrivals.map((product, i) =>
            <div className="px-2" key={i}>
              <Product
                _id={product.id}
                id={product.id}
                img={`${apiFiles}/${product.id}/${product.files[0]}`}
                productName={product.nombre}
                price={product.precio}
                color={product.color}
                badge={true}
                des={product.descripcion}
                files={product.files}
                nombre={product.nombre}
                descripcion={product.descripcion}
                precio={product.precio}
                tallas={product.inventario.tallas}
                precio_envio={product.precio_envio}
              />
            </div>
          )
        }
      </Slider>
    </div>
  );
};

export default NewArrivals;
