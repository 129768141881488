import axios from "axios";

const api = "https://api.cane-clothing.com/api"
export const apiFiles = "https://api.cane-clothing.com/assets/files/productfiles"


export const caneApi = axios.create({
  baseURL: api,
});

// interceptors errors handlers
caneApi.interceptors.request.use((config) => {
  config.headers = {
    ...config.headers,
    //   "x-token": localStorage.getItem("token"),
  };

  return config;
});