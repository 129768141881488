import React, { useState, useEffect } from "react";
import { SplOfferData } from "../../../constants";
import { apiFiles } from "../../../api";

const ProductsOnSale = ({ productInfo, setImage }) => {
  const [images, setImages] = useState([])
  console.log(productInfo);

  useEffect(() => {
    setImages(productInfo.files)
  }, [productInfo])

  const handleChangeImage = (fileName) => {
    setImage(fileName)
  }

  return (
    <div>
      <h3 className="font-titleFont text-xl font-semibold mb-6 underline underline-offset-4 decoration-[1px]">
        Imágenes
      </h3>
      <div className="flex flex-col gap-2">
        {images && images.map((item, i) => (
          <div
            key={i}
            className="flex items-center justify-center gap-4 border-b-[1px] border-b-gray-300 py-2"
            onClick={() => handleChangeImage(`${apiFiles}/${productInfo.id}/${productInfo.files[i]}`)}
          >
            <div className="cursor-pointer">
              <img className="w-24" src={`${apiFiles}/${productInfo.id}/${productInfo.files[i]}`} alt={i} />
            </div>
            {/* <div className="flex flex-col gap-2 font-titleFont">
              <p className="text-base font-medium">{item.productName}</p>
              <p className="text-sm font-semibold">${item.price}</p>
            </div> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductsOnSale;
