import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import { useFormik } from 'formik';
import { toast } from "react-toastify";
import { caneApi } from "../../api";

export const Contact = () => {
  const [successMsg, setSuccessMsg] = useState(null)
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState("");
  useEffect(() => {
    setPrevLocation(location.state.data);
  }, [location]);

  const validate = (values) => {
    const errors = {};
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!values.nombre) {
      errors.nombre = 'El campo nombre es obligatorio';
    }
    if (!values.correo) {
      errors.correo = 'El campo email es obligatorio';
    } else if (!emailRegex.test(values.correo)) {
      errors.correo = 'El correo electrónico no es válido';
    }
    if (!values.comentarios) {
      errors.comentarios = 'El campo mensaje es obligatorio';
    }
    return errors;
  };


  const formik = useFormik({
    initialValues: {
      nombre: "",
      correo: "",
      comentarios: "",
    },
    validate,
    onSubmit: values => {
      sendEmail(values)
    },
  });

  const sendEmail = async (values) => {
    console.log(values);

    try {
      await caneApi.post(`/sendMail`, null, {
        params: {
          ...values
        }
      });
      toast.success("Mensaje enviado correctamente", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
      });
      setSuccessMsg("Mensaje enviado correctamente")
      formik.resetForm()
    } catch (error) {
      console.log(error);
      toast.error("Error al enviar su mensaje", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
      })
      setSuccessMsg(null)
    }
  }
  return (
    <div className="max-w-container mx-auto px-4">
      <Breadcrumbs title="Contacto" prevLocation={prevLocation} />
      {successMsg ? (
        <p className="pb-20 w-96 font-medium text-green-500">{successMsg}</p>
      ) : (
        <form className="pb-20" onSubmit={formik.handleSubmit}>
          <h1 className="font-titleFont font-semibold text-3xl">
            Rellena el formulario
          </h1>
          <div className="max-w-[500px] h-auto py-6 flex flex-col gap-6">
            <div>
              <p className="text-base font-titleFont font-semibold px-2">
                Nombre
              </p>
              <input
                onChange={formik.handleChange}
                value={formik.values.nombre}
                name="nombre"
                className="w-full py-1 border-b-2 px-2 text-base font-medium placeholder:font-normal placeholder:text-sm outline-none focus-within:border-primeColor"
                type="text"
                placeholder="Introduce tu nombre aquí"
              />
              {formik.errors.nombre && formik.touched.nombre && (
                <p className="text-red-500 text-sm font-titleFont font-semibold mt-1 px-2 flex items-center gap-1">
                  <span className="text-sm italic font-bold">!</span>
                  {formik.errors.nombre}
                </p>
              )}
            </div>
            <div>
              <p className="text-base font-titleFont font-semibold px-2">
                Email
              </p>
              <input
                onChange={formik.handleChange}
                value={formik.values.correo}
                name="correo"
                className="w-full py-1 border-b-2 px-2 text-base font-medium placeholder:font-normal placeholder:text-sm outline-none focus-within:border-primeColor"
                type="email"
                placeholder="Introduce tu email aquí"
              />
              {formik.errors.correo && formik.touched.correo && (
                <p className="text-red-500 text-sm font-titleFont font-semibold mt-1 px-2 flex items-center gap-1">
                  <span className="text-sm italic font-bold">!</span>
                  {formik.errors.correo}
                </p>
              )}
            </div>
            <div>
              <p className="text-base font-titleFont font-semibold px-2">
                Mensaje
              </p>
              <textarea
                onChange={formik.handleChange}
                value={formik.values.comentarios}
                name="comentarios"
                cols="30"
                rows="3"
                className="w-full py-1 border-b-2 px-2 text-base font-medium placeholder:font-normal placeholder:text-sm outline-none focus-within:border-primeColor resize-none"
                type="text"
                placeholder="Introduce tu mensaje aquí"
              ></textarea>
              {formik.errors.comentarios && formik.touched.comentarios && (
                <p className="text-red-500 text-sm font-titleFont font-semibold mt-1 px-2 flex items-center gap-1">
                  <span className="text-sm italic font-bold">!</span>
                  {formik.errors.comentarios}
                </p>
              )}
            </div>
            <button
              type="submit"
              className="w-44 bg-primeColor text-gray-200 h-10 font-titleFont text-base tracking-wide font-semibold hover:bg-secondaryColor hover:text-white duration-200"
            >
              Enviar
            </button>
          </div>
        </form>
      )}
    </div>
  );
};
