import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import Image from "../../components/designLayouts/Image";
import { opcion2, opcion3, opcion780 } from "../../assets/images";

export const About = () => {

  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState("");
  useEffect(() => {
    setPrevLocation(location.state.data);
  }, [location]);

  return (
    <div className="max-w-container mx-auto px-4">
      <Breadcrumbs title="Nosotros" prevLocation={prevLocation} />
      <div className="pb-20 flex flex-col md:flex-row items-start justify-between gap-4 lg:gap-10">
        <div className="w-full md:w-2/3 lg:w-1/2 h-full">
          <Link to="/shop">
            <Image className="h-full w-full object-cover" imgSrc={opcion780} />
          </Link>
        </div>
        <div className="w-full md:w-2/3 lg:w-1/2 h-auto flex flex-col gap-4 lg:gap-10">
          <div className="h-1/2 w-full">
            <h2 className="text-3xl font-semibold">Calidad y Estilo sin Compromisos</h2>
            <hr className="h-px my-4 bg-primeColor border-0 dark:bg-primeColor" />
            <p className="text-lightText">Nos enorgullecemos de la calidad de nuestros productos. Cada par de jeans que vendemos ha sido cuidadosamente fabricado con materiales de primera calidad y una atención meticulosa a los detalles. Queremos que te sientas segura de que estás adquiriendo prendas que durarán en tu guardarropa por mucho tiempo.</p>
          </div>
          <div className="h-1/2 w-full">
            <h2 className="text-3xl font-semibold">Nuestra Pasión por los Jeans</h2>
            <hr className="h-px my-4 bg-primeColor border-0 dark:bg-primeColor" />
            <p className="text-lightText">En nuestro equipo, compartimos una pasión inquebrantable por los jeans. Creemos que no hay prenda más versátil y atemporal que un buen par de pantalones de mezclilla. Por eso, nos esforzamos por seleccionar cuidadosamente los mejores diseños y materiales para ofrecerte una experiencia de compra excepcional.</p>
          </div>

        </div>
      </div>
    </div>
  );
};
