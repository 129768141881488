import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import { productOfTheYear } from "../../../assets/images";
import ShopNow from "../../designLayouts/buttons/ShopNow";
import Image from "../../designLayouts/Image";
import { caneApi, apiFiles } from "../../../api/caneApi";

const YearProduct = () => {
  const [randomProduct, setRandomProduct] = useState(null);
  const [imageOfProduct, setImageOfProduct] = useState(null);

  const getProducts = async () => {
    const { data } = await caneApi.get("/getProductos");
    const randomIndex = Math.floor(Math.random() * data.inventory.length);
    const selectedProduct = data.inventory[randomIndex];
    setRandomProduct(selectedProduct);
    const src = `${apiFiles}/${selectedProduct.id}/${selectedProduct.files[0]}`;
    setImageOfProduct(src);
  };

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <Link to="/shop">
      <div className="w-full h-80 mb-20 bg-[#f3f3f3] relative font-titleFont">
        {imageOfProduct && (
          <div className="absolute left-0 top-0 h-full w-full md:w-1/2 flex items-center justify-center">
            <div className="w-52 h-52 rounded-full overflow-hidden">
              <Image className="w-full h-full object-cover" imgSrc={imageOfProduct} />
            </div>
          </div>
        )}
        <div className="w-full md:w-2/3 lg:w-1/2 xl:w-1/2 h-80 absolute px-4 md:px-0 top-0 right-0 flex flex-col items-start gap-6 justify-center">
          <h1 className="text-3xl font-semibold text-primeColor">Artículo del año</h1>
          <p className="text-base font-normal text-primeColor max-w-[600px] mr-4">
            {randomProduct ? randomProduct.extracto : "Cargando..."}
          </p>
          <ShopNow />
        </div>
      </div>
    </Link>
  );
};

export default YearProduct;
