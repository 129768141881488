import React, { useEffect } from 'react';
import Breadcrumbs from '../../components/pageProps/Breadcrumbs';
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { FaCheck } from "react-icons/fa";
import { useSearchParams } from "react-router-dom";
import { caneApi } from '../../api';
import { toast } from "react-toastify";

export const PaymentSuccess = () => {

    const [searchParams] = useSearchParams();
    const c = searchParams.get("c");

    useEffect(() => {
        updateOrder();
    }, []);

    const updateOrder = async () => {
        try {
            const { data } = await caneApi.post(
                `/compra_updateStatus`,
                null,
                {
                    params: {
                        c: c,
                        status: "Complete",
                    },
                }
            );
            console.log(data);
            if (data.status === "error") {
                toast.error("Ha ocurrido un error", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 3000,
                    hideProgressBar: true,
                })
                return;
            }
            toast.success("Estado de compara actualizado", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
                hideProgressBar: true,
            })
        } catch (error) {
            console.log(error);
            toast.error("Ha ocurrido un error", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
                hideProgressBar: true,
            })
        }
    };

    return (
        <div className="max-w-container mx-auto px-4">
            <Breadcrumbs title="Pago exitoso" />
            <motion.div
                initial={{ y: 30, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.4 }}
                className="flex flex-col mdl:flex-row justify-center items-center gap-4 pb-20"
            >
                <div className="rounded-full bg-white p-3 shadow-md">
                    <FaCheck size={60} color="#66BB6A" />
                </div>
                <div className="max-w-[500px] p-4 py-8 bg-white flex gap-4 flex-col items-center rounded-md shadow-lg">
                    <h1 className="font-titleFont text-xl font-bold uppercase">
                        ¡Pago exitoso!
                    </h1>
                    <p className="text-sm text-center px-10 -mt-2">
                        ¡Gracias por tu compra! Tu pago ha sido procesado correctamente.
                    </p>
                    <Link to="/shop">
                        <button className="bg-primeColor rounded-md cursor-pointer hover:bg-secondaryColor active:bg-gray-900 px-8 py-2 font-titleFont font-semibold text-lg text-gray-200 hover:text-white duration-300">
                            Ir a la tienda
                        </button>
                    </Link>
                </div>
            </motion.div>
        </div>
    );
};
