import React from "react";
import Footer from "../components/home/Footer/Footer";
import FooterBottom from "../components/home/Footer/FooterBottom";
import Header from "../components/home/Header/Header";
import HeaderBottom from "../components/home/Header/HeaderBottom";
import SpecialCase from "../components/SpecialCase/SpecialCase";
import {
    Outlet,
    // ScrollRestoration,
  } from "react-router-dom";

export const Layout = () => {
    return (
      <div>
        <Header />
        <HeaderBottom />
        <SpecialCase />
        {/* <ScrollRestoration /> */}
        <Outlet />
        <Footer />
        <FooterBottom />
      </div>
    );
  };