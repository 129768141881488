import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from 'formik';
import { motion } from "framer-motion";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import { emptyCart } from "../../assets/images/index";
import { ItemCard } from "./ItemCard";
import { useCartStore } from "../../hooks/useCartStore";
import { toast } from "react-toastify";
import { BillingInfoModal } from "../../components/pageProps/shopPage/BillingInfoModal";

export const Cart = () => {
  const { products, resetCartStore, applyCoupon, totalPrice, totalPriceShipping } = useCartStore();

  const [coupon, setCoupon] = useState("")
  const [showModalBillingInfo, setShowModalBillingInfo] = useState(false)


  const hanldeUpdateCart = () => {
    toast.success("Carrito actualizado", {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 3000,
      hideProgressBar: true,
    })
  }

  const handleChangeCoupon = (e) => {
    setCoupon(e.target.value);
    applyCoupon(e.target.value);
  }

  const goToBillingInfo = () => {
    setShowModalBillingInfo(true)
  }

  return (
    <div className="max-w-container mx-auto px-4">
      <Breadcrumbs title="Carrito" />
      {products.length > 0 ? (
        <>
          <div className="pb-20">
            <div className="w-full h-20 bg-[#F5F7F7] text-primeColor hidden lgl:grid grid-cols-5 place-content-center px-6 text-lg font-titleFont font-semibold">
              <h2 className="col-span-2">Artículo</h2>
              <h2>Precio</h2>
              <h2>Cantidad</h2>
              <h2>Sub Total</h2>
            </div>
            <div className="mt-5">
              {products.map((item, i) => (
                <div key={i}>
                  <ItemCard item={item} />
                </div>
              ))}
            </div>

            <button
              onClick={() => resetCartStore()}
              className="py-2 px-10 bg-red-500 text-white font-semibold uppercase mb-4 hover:bg-red-700 duration-300"
            >
              Eliminar todo
            </button>

            <div className="flex flex-col mdl:flex-row justify-between border py-4 px-4 items-center gap-2 mdl:gap-0">
              <div className="flex items-center gap-4">
                <input
                  className="w-44 mdl:w-52 h-8 px-4 border text-primeColor text-sm outline-none border-gray-400"
                  type="text"
                  placeholder="Coupon Number"
                  value={coupon}
                  onChange={(e) => handleChangeCoupon(e)}
                />
                {/* <p className="text-sm mdl:text-base font-semibold">
                Aplicar cúpon
              </p> */}
              </div>
              <p className="text-lg font-semibold cursor-pointer"
                onClick={hanldeUpdateCart}
              >Actualizar carrito</p>
            </div>
            <div className="max-w-7xl gap-4 flex justify-end mt-4">
              <div className="w-96 flex flex-col gap-4">
                <h1 className="text-2xl font-semibold text-right">Resumen</h1>
                <div>
                  <p className="flex items-center justify-between border-[1px] border-gray-400 border-b-0 py-1.5 text-lg px-4 font-medium">
                    Subtotal
                    <span className="font-semibold tracking-wide font-titleFont">
                      ${totalPrice} MXN
                    </span>
                  </p>
                  <p className="flex items-center justify-between border-[1px] border-gray-400 border-b-0 py-1.5 text-lg px-4 font-medium">
                    Envío
                    <span className="font-semibold tracking-wide font-titleFont">
                      ${totalPriceShipping} MXN
                    </span>
                  </p>
                  <p className="flex items-center justify-between border-[1px] border-gray-400 py-1.5 text-lg px-4 font-medium">
                    Total
                    <span className="font-bold tracking-wide text-lg font-titleFont">
                      ${totalPrice + totalPriceShipping} MXN
                    </span>
                  </p>
                </div>
                <div className="flex justify-end">
                  {/* <Link to="/paymentgateway"> */}
                  <button className="w-52 h-10 bg-primeColor text-white hover:bg-black duration-300"
                    onClick={goToBillingInfo}
                  >
                    Ir al pago
                  </button>
                  {/* </Link> */}
                </div>
              </div>
            </div>
          </div>
          {/*  Modal billing info */}
          <BillingInfoModal showModalBillingInfo={showModalBillingInfo} setShowModalBillingInfo={setShowModalBillingInfo} />
        </>
      ) : (
        <motion.div
          initial={{ y: 30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.4 }}
          className="flex flex-col mdl:flex-row justify-center items-center gap-4 pb-20"
        >
          <div>
            <img
              className="w-80 rounded-lg p-4 mx-auto"
              src={emptyCart}
              alt="emptyCart"
            />
          </div>
          <div className="max-w-[500px] p-4 py-8 bg-white flex gap-4 flex-col items-center rounded-md shadow-lg">
            <h1 className="font-titleFont text-xl font-bold uppercase">
              Tu carrito está vacío
            </h1>
            <p className="text-sm text-center px-10 -mt-2">
              Tu carrito vive para servir. Dale un propósito: llénalo de ropa.
            </p>
            <Link to="/shop">
              <button className="bg-primeColor rounded-md cursor-pointer hover:bg-secondaryColor active:bg-gray-900 px-8 py-2 font-titleFont font-semibold text-lg text-gray-200 hover:text-white duration-300">
                Ir a la tienda
              </button>
            </Link>
          </div>
        </motion.div>
      )}
    </div>
  );
};
