import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { PrivateRoute } from "./PrivateRoute";
import { useAuthStore } from "../hooks/useAuthStore";
import { Layout } from "../components/Layout";
import {
  Payment,
  Profile,
  SignIn,
  SignUp,
  Home,
  Shop,
  About,
  Contact,
  Journal,
  Offer,
  ProductDetails,
  Cart
} from "../pages";
import { PaymentSuccess, PaymentError } from "../pages/payment";

export const AppRouter = () => {
  const { checkAuthToken } = useAuthStore();

  useEffect(() => {
    checkAuthToken();
  }, []);

  return (
    <Routes>
      <Route path="/signin" element={<SignIn />} />
      <Route path="/signup" element={<SignUp />} />

      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/journal" element={<Journal />} />
        <Route path="/offer" element={<Offer />} />
        <Route path="/product/:_id" element={<ProductDetails />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/paymentgateway" element={<Payment />} />
        <Route path="/transaction/success" element={<PaymentSuccess />} />
        <Route path="/transaction/failure" element={<PaymentError />} />

        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />
      </Route>

      {/* Redireccionar a la página de inicio si la ruta no coincide con ninguna de las anteriores */}
      <Route path="/*" element={<Navigate to="/" />} />
    </Routes>
  );
}


