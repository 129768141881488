import React, { useEffect, useState } from "react";
import Heading from "../Products/Heading";
import Product from "../Products/Product";
import { caneApi, apiFiles } from "../../../api";

const SpecialOffers = () => {
  const [specialProducts, setspecialProducts] = useState([])

  const getSpecialOffers = async () => {
    const { data } = await caneApi.get("/getProductosDestacados");
    const products = data.inventory
    const selectedspecialOffers = products.slice(0, 4);
    setspecialProducts(selectedspecialOffers);
  }

  useEffect(() => {
    getSpecialOffers();
  }, [])

  return (
    <div className="w-full pb-20">
      <Heading heading="Ofertas especiales" />
      <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10">
        {specialProducts.map((product, i) => (
          <Product
            _id={product.id}
            id={product.id}
            key={i}
            img={`${apiFiles}/${product.id}/${product.files[0]}`}
            productName={product.nombre}
            price={product.precio}
            color={product.color}
            des={product.descripcion}
            files={product.files}
            nombre={product.nombre}
            descripcion={product.descripcion}
            precio={product.precio}
            precio_envio={product.precio_envio}
            tallas={product.inventario.tallas}
          />
        ))}
      </div>
    </div>
  );
};

export default SpecialOffers;
