import React, { useEffect, useState } from "react";
import Heading from "../Products/Heading";
import Product from "../Products/Product";
import { caneApi, apiFiles } from "../../../api";

const BestSellers = () => {
  const [bestSellers, setBestSellers] = useState([])

  const getBestSellers = async () => {
    const { data } = await caneApi.get("/getProductosBestSeller");
    const products = data.inventory
    const selectedBestSellers = products.slice(0, 4);
    setBestSellers(selectedBestSellers);
  }

  useEffect(() => {
    getBestSellers();
  }, [])

  return (
    <div className="w-full pb-20">
      <Heading heading="Más vendidos" />
      <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10">
        {bestSellers.map((product, i) => (
          <Product
            _id={product.id}
            id={product.id}
            key={i}
            img={`${apiFiles}/${product.id}/${product.files[0]}`}
            productName={product.nombre}
            price={product.precio}
            color={product.color}
            des={product.descripcion}
            files={product.files}
            nombre={product.nombre}
            descripcion={product.descripcion}
            precio={product.precio}
            tallas={product.inventario.tallas}
            precio_envio={product.precio_envio}
          />
        ))}
      </div>
    </div>
  );
};

export default BestSellers;
