import { BrowserRouter } from "react-router-dom";
import { AppRouter } from "./router/AppRouter";
import { ToastContainer } from 'react-toastify';
function App() {
  return (
    <div className="font-bodyFont">
      {/* For notification use toastify */}
      <ToastContainer />  
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
    </div>
  );
}

export default App;
