import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import {
  onCheckLogin,
  onLogin,
  onLogout,
  clearErrorMessage,
} from "../redux/auth/authSlice";
import { caneApi } from "../api";

export const useAuthStore = () => {
  const { status, user, errorMessage } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();
  const { from } = location.state || { from: { pathname: "/" } };

  const startLogin = async ({ mail, password }) => {
    console.log(mail);
    console.log(password);
    dispatch(onCheckLogin());
    try {
      // const response = await caneApi.post(`/user_login?mail=${mail}&password=${password}`);
      const { data } = await caneApi.post('/user_login', null, {
        params: {
          mail,
          password
        }
      });
      localStorage.setItem("token", data.result);
      localStorage.setItem("token-init-date", new Date().getTime());
      const userData = data.userData[0]
      dispatch(onLogin({ ...userData }));
      navigate(from);
      toast.success("Inicio de sesión correcto", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
      });
    } catch (error) {
      console.log(error);
      dispatch(onLogout("Error de autenticación"));
      toast.error("Error de autenticación", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
      })
      // setTimeout(() => {
      //   dispatch(clearErrorMessage());
      // }, 10);
    }
  };

  const startRegister = async ({ ...params }) => {
    console.log({ ...params });
    dispatch(onCheckLogin());
    try {
      const { data } = await caneApi.post("/user_register", null, {
        params: {
          ...params
        }
      });
      localStorage.setItem("token", data.cookie);
      localStorage.setItem("token-init-date", new Date().getTime());
      const userData = data.result[0]
      dispatch(onLogin({ ...userData }));
      navigate(from);
      toast.success("Registro correcto", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
      });
    } catch (error) {
      console.log(error);
      dispatch(onLogout("Error en el registro"));
      toast.error("Error al registrarse", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
      })
      // setTimeout(() => {
      //   dispatch(clearErrorMessage());
      // }, 10);
    }
  };

  const checkAuthToken = async () => {
    const token = localStorage.getItem("token");
    if (!token) return dispatch(onLogout());

    try {
      const { data } = await caneApi.post('/user_login_with_token', null, {
        params: {
          cookie: token
        }
      });
      localStorage.setItem("token", data.result);
      localStorage.setItem("token-init-date", new Date().getTime());
      const userData = data.userData[0]
      dispatch(onLogin({ ...userData }));
    } catch (error) {
      localStorage.clear();
      dispatch(onLogout());
    }
  };

  const startLogout = () => {
    localStorage.clear();
    dispatch(onLogout());
    dispatch(clearErrorMessage());
    toast.success("Has cerrado sesión", {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 3000,
      hideProgressBar: true,
    });
  };

  return {
    //* Propiedades
    errorMessage,
    status,
    user,

    //* Métodos
    checkAuthToken,
    startLogin,
    startLogout,
    startRegister,
  };
};
