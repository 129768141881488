import React, { useEffect, useState } from 'react'
import Modal from "react-modal";
import { useFormik } from 'formik';
import { useAuthStore } from '../../../hooks/useAuthStore';
import { caneApi } from '../../../api';
import { useCartStore } from '../../../hooks/useCartStore';
import { toast } from 'react-toastify';

export const BillingInfoModal = ({ showModalBillingInfo, setShowModalBillingInfo }) => {
    const [token, setToken] = useState(null)
    const { user } = useAuthStore();

    const { coupon_code, products, resetCartStore } = useCartStore();

    const handleCloseModal = () => {
        setShowModalBillingInfo(false)
    }


    const validate = (values) => {
        const errors = {};
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (!values.nombre) {
            errors.nombre = 'El campo nombre es obligatorio';
        }
        if (!values.email) {
            errors.email = 'El campo email es obligatorio';
        } else if (!emailRegex.test(values.email)) {
            errors.email = 'El correo electrónico no es válido';
        }
        if (!values.telefono) {
            errors.telefono = 'El campo telefono es obligatorio';
        }
        if (!values.estado) {
            errors.estado = 'El campo estado es obligatorio';
        }
        if (!values.pais) {
            errors.pais = 'El campo pais es obligatorio';
        }
        if (!values.ciudad) {
            errors.ciudad = 'El campo ciudad es obligatorio';
        }
        if (!values.cpostal) {
            errors.cpostal = 'El campo cpostal es obligatorio';
        }
        if (!values.direccion) {
            errors.direccion = 'El campo direccion es obligatorio';
        }
        if (!values.colonia) {
            errors.colonia = 'El campo colonia es obligatorio';
        }
        return errors;
    };

    useEffect(() => {
        if (user) {
            formik.setValues({
                nombre: user.nombre,
                email: user.email,
                telefono: user.telefono,
                estado: user.estado,
                pais: user.pais,
                ciudad: user.ciudad,
                cpostal: user.cpostal,
                direccion: user.direccion,
                colonia: user.colonia
            });
            setToken(localStorage.getItem("token"));
        }
    }, [user]);

    const formik = useFormik({
        initialValues: {
            nombre: '',
            email: '',
            telefono: '',
            estado: '',
            pais: '',
            ciudad: '',
            cpostal: '',
            direccion: '',
            colonia: ''
        },
        validate,
        onSubmit: values => {
            goToCheckOut(values)
        },
    });


    const goToCheckOut = async (values) => {
        console.log(values);
        console.log(coupon_code);
        console.log(token);

        const products_to_buy = JSON.stringify(products.map((product) =>
        ({
            id: product._id,
            talla_id: product.selectedSize,
            cantidad: product.quantity,
        })))
        console.log(products_to_buy);

        try {
            const { data } = await caneApi.get(`/get_payment`, {
                params: {
                    ...values,
                    cupon_codigo: coupon_code,
                    cookie: token,
                    products: products_to_buy
                }
            })
            console.log(data);
            if (data.status === "error") {
                toast.error(data.result, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 3000,
                    hideProgressBar: true,
                })
                return;
            }
            window.open(data.url, "_blank");
            resetCartStore();
        } catch (error) {
            console.log(error);
            toast.error("Error al hacer el pago", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
                hideProgressBar: true,
            })
        }
    }

    return (
        <Modal
            isOpen={showModalBillingInfo}
            // onAfterOpen={afterOpenModal}
            onRequestClose={handleCloseModal}
            className="modal"
            overlayClassName="modal-fondo"
            closeTimeoutMS={200}
            contentLabel="ModalService"
        >
            <div className="relative pt-8">
                <h1 className="text-2xl font-semibold">
                    Información de envío
                </h1>
                <hr className="h-px my-4 bg-primeColor border-0 dark:bg-primeColor" />
                <form onSubmit={formik.handleSubmit} className='w-full lgl:w-[500px] flex items-center justify-center overflow-y-scroll scrollbar-thin scrollbar-thumb-primeColor'>
                    <div className="px-6 py-4 w-full flex flex-col justify-start">
                        <div className="flex flex-col gap-3">
                            {/* client name */}
                            <div className="flex flex-col gap-.5">
                                <p className="font-titleFont text-base font-semibold text-gray-600">
                                    Nombre completo
                                </p>
                                <input
                                    name="nombre"
                                    onChange={formik.handleChange}
                                    value={formik.values.nombre}
                                    className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                                    type="text"
                                    placeholder="eg. John Doe"
                                />
                                {formik.errors.nombre && formik.touched.nombre && (
                                    <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                                        <span className="font-bold italic mr-1">!</span>
                                        {formik.errors.nombre}
                                    </p>
                                )}
                            </div>
                            {/* Email */}
                            <div className="flex flex-col gap-.5">
                                <p className="font-titleFont text-base font-semibold text-gray-600">
                                    Email
                                </p>
                                <input
                                    name="email"
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                    className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                                    type="email"
                                    placeholder="ornelas@workemail.com"
                                />
                                {formik.errors.email && formik.touched.email && (
                                    <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                                        <span className="font-bold italic mr-1">!</span>
                                        {formik.errors.email}
                                    </p>
                                )}
                            </div>
                            {/* Phone Number */}
                            <div className="flex flex-col gap-.5">
                                <p className="font-titleFont text-base font-semibold text-gray-600">
                                    Número de teléfono
                                </p>
                                <input
                                    name="telefono"
                                    onChange={formik.handleChange}
                                    value={formik.values.telefono}
                                    className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                                    type="text"
                                    placeholder="+52 624 147 70 77"
                                />
                                {formik.errors.telefono && formik.touched.telefono && (
                                    <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                                        <span className="font-bold italic mr-1">!</span>
                                        {formik.errors.telefono}
                                    </p>
                                )}
                            </div>
                            {/* Address */}
                            <div className="flex flex-col gap-.5">
                                <p className="font-titleFont text-base font-semibold text-gray-600">
                                    Dirección
                                </p>
                                <input
                                    name="direccion"
                                    onChange={formik.handleChange}
                                    value={formik.values.direccion}
                                    className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                                    type="text"
                                    placeholder="Calle y número"
                                />
                                {formik.errors.direccion && formik.touched.direccion && (
                                    <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                                        <span className="font-bold italic mr-1">!</span>
                                        {formik.errors.direccion}
                                    </p>
                                )}
                            </div>
                            {/* Neighborhood */}
                            <div className="flex flex-col gap-.5">
                                <p className="font-titleFont text-base font-semibold text-gray-600">
                                    Colonia
                                </p>
                                <input
                                    name="colonia"
                                    onChange={formik.handleChange}
                                    value={formik.values.colonia}
                                    className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                                    type="text"
                                    placeholder="Colonia"
                                />
                                {formik.errors.colonia && formik.touched.colonia && (
                                    <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                                        <span className="font-bold italic mr-1">!</span>
                                        {formik.errors.colonia}
                                    </p>
                                )}
                            </div>
                            {/* City */}
                            <div className="flex flex-col gap-.5">
                                <p className="font-titleFont text-base font-semibold text-gray-600">
                                    Ciudad
                                </p>
                                <input
                                    name="ciudad"
                                    onChange={formik.handleChange}
                                    value={formik.values.ciudad}
                                    className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                                    type="text"
                                    placeholder="Ciudad"
                                />
                                {formik.errors.ciudad && formik.touched.ciudad && (
                                    <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                                        <span className="font-bold italic mr-1">!</span>
                                        {formik.errors.ciudad}
                                    </p>
                                )}
                            </div>
                            {/* State */}
                            <div className="flex flex-col gap-.5">
                                <p className="font-titleFont text-base font-semibold text-gray-600">
                                    Estado
                                </p>
                                <input
                                    name="estado"
                                    onChange={formik.handleChange}
                                    value={formik.values.estado}
                                    className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                                    type="text"
                                    placeholder="Estado"
                                />
                                {formik.errors.estado && formik.touched.estado && (
                                    <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                                        <span className="font-bold italic mr-1">!</span>
                                        {formik.errors.estado}
                                    </p>
                                )}
                            </div>
                            {/* Country */}
                            <div className="flex flex-col gap-.5">
                                <p className="font-titleFont text-base font-semibold text-gray-600">
                                    País
                                </p>
                                <input
                                    name="pais"
                                    onChange={formik.handleChange}
                                    value={formik.values.pais}
                                    className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                                    type="text"
                                    placeholder="País"
                                />
                                {formik.errors.pais && formik.touched.pais && (
                                    <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                                        <span className="font-bold italic mr-1">!</span>
                                        {formik.errors.pais}
                                    </p>
                                )}
                            </div>
                            {/* Zip code */}
                            <div className="flex flex-col gap-.5">
                                <p className="font-titleFont text-base font-semibold text-gray-600">
                                    C. Postal
                                </p>
                                <input
                                    name="cpostal"
                                    onChange={formik.handleChange}
                                    value={formik.values.cpostal}
                                    className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                                    type="text"
                                    placeholder="Código Postal"
                                />
                                {formik.errors.cpostal && formik.touched.cpostal && (
                                    <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                                        <span className="font-bold italic mr-1">!</span>
                                        {formik.errors.cpostal}
                                    </p>
                                )}
                            </div>
                            <button
                                type='submit'
                                className="bg-primeColor text-gray-200 h-10 font-titleFont text-base tracking-wide font-semibold hover:bg-secondaryColor hover:text-white duration-200"
                            >
                                Ir a la compra
                            </button>

                        </div>
                    </div>
                </form>
            </div>
        </Modal>
    )
}
