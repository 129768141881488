import React, { useState } from "react";
import { useCartStore } from "../../../hooks/useCartStore";
import { apiFiles } from "../../../api";

const ProductInfo = ({ productInfo }) => {
  const { addItemToCart } = useCartStore();
  const [selectedSize, setSelectedSize] = useState("");
  console.log(productInfo);
  console.log(productInfo.tallas);

  const handleSizeChange = (event) => {
    setSelectedSize(event.target.value);
  };

  return (
    <div className="flex flex-col gap-5">
      <h2 className="text-4xl font-semibold">{productInfo.nombre}</h2>
      <p className="text-xl font-semibold">${productInfo.precio}</p>
      <p className="text-base text-gray-600">{productInfo.descripcion}</p>
      {/* <p className="text-sm">Be the first to leave a review.</p> */}
      <p className="font-medium text-lg">
        <span className="font-normal">Color:</span> {productInfo.color}
      </p>
      <label className="font-medium text-lg">
        <span className="font-normal pr-2">Tallas:</span>
        <select value={selectedSize} onChange={handleSizeChange}>
          <option value="" className="
          text-base text-gray-600">Selección</option>
          {productInfo && productInfo.inventario?.tallas.map((talla, index) => (
            <option key={index} value={index} className="text-base text-gray-600">
              {talla.nombre} - Inventario: {productInfo.inventario?.tallas[index].cantidad}
            </option>
          ))}
          {productInfo && productInfo.tallas?.map((talla, index) => (
            <option key={index} value={index} className="text-base text-gray-600">
              {talla.nombre} - Inventario: {talla.cantidad}
            </option>
          ))}
        </select>
      </label>
      <button
        onClick={() =>
          addItemToCart({
            _id: productInfo.id,
            name: productInfo.productName || productInfo.nombre,
            quantity: 1,
            image: productInfo.img || `${apiFiles}/${productInfo.id}/${productInfo.files[0]}`,
            badge: productInfo.badge,
            price: productInfo.price || productInfo.precio,
            colors: productInfo.color,
            descripcion: productInfo.descripcion,
            sizes: productInfo.tallas || productInfo.inventario.tallas,
            selectedSize: selectedSize !== "" ? selectedSize : "0",
            precio_envio: productInfo.precio_envio,
            files: productInfo.files,
          })
        }
        className="w-full py-4 bg-primeColor hover:bg-secondaryColor duration-300 text-white text-lg font-titleFont"
        disabled={selectedSize === ""}
      >
        Añadir al carrito
      </button>
      {/* <p className="font-normal text-sm">
        <span className="text-base font-medium"> Categories:</span> Spring
        collection, Streetwear, Women Tags: featured SKU: N/A
      </p> */}
    </div>
  );
};

export default ProductInfo;
