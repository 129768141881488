import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import NavTitle from "./NavTitle";
import { caneApi } from "../../../../api";
import { useShopStore } from "../../../../hooks/useShopStore";

const Categorys = () => {
  const { filters, handleSetFilterByCategory } = useShopStore();
  const [showBrands, setShowBrands] = useState(true);
  const [categorys, setCategorys] = useState([])

  // const brands = [
  //   {
  //     _id: 9006,
  //     title: "Apple",
  //   },
  //   {
  //     _id: 9007,
  //     title: "Ultron",
  //   },
  //   {
  //     _id: 9008,
  //     title: "Unknown",
  //   },
  //   {
  //     _id: 9009,
  //     title: "Shoppers Home",
  //   },
  //   {
  //     _id: 9010,
  //     title: "Hoichoi",
  //   },
  // ];

  useEffect(() => {
    getCategorys();
  }, [])

  const getCategorys = async () => {
    const { data } = await caneApi.get("/get_categs");
    // console.log(data);
    setCategorys(data.result)
  }

  return (
    <div>
      <div
        onClick={() => setShowBrands(!showBrands)}
        className="cursor-pointer"
      >
        <NavTitle title="Compra por categoría" icons={true} />
      </div>
      {showBrands && (
        <motion.div
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <ul className="cursor-pointer flex flex-col gap-4 text-sm lg:text-base text-[#767676]">
            <li
              className={`border-b-[1px] border-b-[#F0F0F0] pb-2 flex items-center gap-2 hover:text-primeColor hover:border-gray-400 duration-300`}
              onClick={() => handleSetFilterByCategory("Todos")}
            >
              Todos
            </li>
            {categorys && categorys.map((item, i) => (
              <li
                key={i}
                className={`border-b-[1px]  pb-2 flex items-center gap-2 hover:text-primeColor hover:border-gray-400 ${filters.filterByCategory === `${item.categoria_nombre}` ? "text-primeColor" : ""} ${filters.filterByCategory === `${item.categoria_nombre}` ? "border-gray-400" : "border-b-[#F0F0F0]"} duration-300`}
                onClick={() => handleSetFilterByCategory(item.categoria_nombre)}
              >
                {item.categoria_nombre}
              </li>
            ))}
          </ul>
        </motion.div>
      )}
    </div>
  );
};

export default Categorys;
