import React from "react";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import { useFormik } from 'formik';
import { useAuthStore } from "../../hooks/useAuthStore";
import { caneApi } from "../../api";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

export const Profile = () => {
  const { user } = useAuthStore();

  const validate = (values) => {
    const errors = {};
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!values.nombre) {
      errors.nombre = 'El campo nombre es obligatorio';
    }
    if (!values.email) {
      errors.email = 'El campo email es obligatorio';
    } else if (!emailRegex.test(values.email)) {
      errors.email = 'El correo electrónico no es válido';
    }
    if (!values.telefono) {
      errors.telefono = 'El campo telefono es obligatorio';
    }
    if (!values.estado) {
      errors.estado = 'El campo estado es obligatorio';
    }
    if (!values.pais) {
      errors.pais = 'El campo pais es obligatorio';
    }
    if (!values.ciudad) {
      errors.ciudad = 'El campo ciudad es obligatorio';
    }
    if (!values.cpostal) {
      errors.cpostal = 'El campo cpostal es obligatorio';
    }
    if (!values.direccion) {
      errors.direccion = 'El campo direccion es obligatorio';
    }
    if (!values.colonia) {
      errors.colonia = 'El campo colonia es obligatorio';
    }

    return errors;
  };


  const formik = useFormik({
    initialValues: {
      nombre: user.nombre,
      email: user.email,
      telefono: user.telefono,
      estado: user.estado,
      pais: user.pais,
      ciudad: user.ciudad,
      cpostal: user.cpostal,
      direccion: user.direccion,
      colonia: user.colonia
    },
    validate,
    onSubmit: values => {
      editProfile(values)
    },
  });

  const editProfile = async (values) => {
    // const { name, email, password, telefono, estado, pais, ciudad, cpostal, direccion, colonia } = values
    console.log({ ...values });
    const cookie = localStorage.getItem("token");

    try {
      const { data } = await caneApi.post(`/update_my_data`, null, {
        params: {
          ...values, cookie
        }
      });
      console.log(data);
      toast.success("Datos actualizados", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
      });
    } catch (error) {
      console.log(error);
      toast.error("Error al actualizar datos", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
      })
    }
  }



  return (
    <div className="max-w-container mx-auto px-4">
      <Breadcrumbs title={user ? user.nombre : "Perfil"} prevLocation={"Inicio"} />
      <div className="pb-10">
        <form onSubmit={formik.handleSubmit} className="lg:w-1/2">
          <div className="flex flex-col gap-3">
            <div className="flex flex-col gap-.5">
              <p className="text-base font-titleFont font-semibold px-2">
                Nombre completo
              </p>
              <input
                className="w-full py-1 border-b-2 text-gray-600 px-2 text-base font-medium placeholder:font-normal placeholder:text-sm outline-none focus-within:border-primeColor"
                type="text"
                name="nombre"
                onChange={formik.handleChange}
                value={formik.values.nombre}
                placeholder="eg. John Doe"
              />
              {formik.errors.nombre && formik.touched.nombre && (
                <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                  <span className="font-bold italic mr-1">!</span>
                  {formik.errors.nombre}
                </p>
              )}
            </div>
            <div className="flex flex-col gap-.5">
              <p className="text-base font-titleFont font-semibold px-2">
                Email
              </p>
              <input
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                className="w-full py-1 border-b-2 text-gray-600 px-2 text-base font-medium placeholder:font-normal placeholder:text-sm outline-none focus-within:border-primeColor"
                type="email"
                placeholder="ornelas@workemail.com"
              />
              {formik.errors.email && formik.touched.email && (
                <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                  <span className="font-bold italic mr-1">!</span>
                  {formik.errors.email}
                </p>
              )}
            </div>
            <div className="flex flex-col gap-.5">
              <p className="text-base font-titleFont font-semibold px-2">
                Número de teléfono
              </p>
              <input
                name="telefono"
                onChange={formik.handleChange}
                value={formik.values.telefono}
                className="w-full py-1 border-b-2 text-gray-600 px-2 text-base font-medium placeholder:font-normal placeholder:text-sm outline-none focus-within:border-primeColor"
                type="text"
                placeholder="+52 624 147 70 77"
              />
              {formik.errors.telefono && formik.touched.telefono && (
                <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                  <span className="font-bold italic mr-1">!</span>
                  {formik.errors.telefono}
                </p>
              )}
            </div>
            <div className="flex flex-col gap-.5">
              <p className="text-base font-titleFont font-semibold px-2">
                Dirección
              </p>
              <input
                name="direccion"
                onChange={formik.handleChange}
                value={formik.values.direccion}
                className="w-full py-1 border-b-2 text-gray-600 px-2 text-base font-medium placeholder:font-normal placeholder:text-sm outline-none focus-within:border-primeColor"
                type="text"
                placeholder="Calle y número"
              />
              {formik.errors.direccion && formik.touched.direccion && (
                <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                  <span className="font-bold italic mr-1">!</span>
                  {formik.errors.direccion}
                </p>
              )}
            </div>
            <div className="flex flex-col gap-.5">
              <p className="text-base font-titleFont font-semibold px-2">
                Colonia
              </p>
              <input
                name="colonia"
                onChange={formik.handleChange}
                value={formik.values.colonia}
                className="w-full py-1 border-b-2 text-gray-600 px-2 text-base font-medium placeholder:font-normal placeholder:text-sm outline-none focus-within:border-primeColor"
                type="text"
                placeholder="Colonia"
              />
              {formik.errors.colonia && formik.touched.colonia && (
                <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                  <span className="font-bold italic mr-1">!</span>
                  {formik.errors.colonia}
                </p>
              )}
            </div>
            <div className="flex flex-col gap-.5">
              <p className="text-base font-titleFont font-semibold px-2">
                Ciudad
              </p>
              <input
                name="ciudad"
                onChange={formik.handleChange}
                value={formik.values.ciudad}
                className="w-full py-1 border-b-2 text-gray-600 px-2 text-base font-medium placeholder:font-normal placeholder:text-sm outline-none focus-within:border-primeColor"
                type="text"
                placeholder="Ciudad"
              />
              {formik.errors.ciudad && formik.touched.ciudad && (
                <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                  <span className="font-bold italic mr-1">!</span>
                  {formik.errors.ciudad}
                </p>
              )}
            </div>
            <div className="flex flex-col gap-.5">
              <p className="text-base font-titleFont font-semibold px-2">
                Estado
              </p>
              <input
                name="estado"
                onChange={formik.handleChange}
                value={formik.values.estado}
                className="w-full py-1 border-b-2 text-gray-600 px-2 text-base font-medium placeholder:font-normal placeholder:text-sm outline-none focus-within:border-primeColor"
                type="text"
                placeholder="Estado"
              />
              {formik.errors.estado && formik.touched.estado && (
                <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                  <span className="font-bold italic mr-1">!</span>
                  {formik.errors.estado}
                </p>
              )}
            </div>
            <div className="flex flex-col gap-.5">
              <p className="text-base font-titleFont font-semibold px-2">
                País
              </p>
              <input
                name="pais"
                onChange={formik.handleChange}
                value={formik.values.pais}
                className="w-full py-1 border-b-2 text-gray-600 px-2 text-base font-medium placeholder:font-normal placeholder:text-sm outline-none focus-within:border-primeColor"
                type="text"
                placeholder="País"
              />
              {formik.errors.pais && formik.touched.pais && (
                <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                  <span className="font-bold italic mr-1">!</span>
                  {formik.errors.pais}
                </p>
              )}
            </div>
            <div className="flex flex-col gap-.5">
              <p className="text-base font-titleFont font-semibold px-2">
                C. Postal
              </p>
              <input
                name="cpostal"
                onChange={formik.handleChange}
                value={formik.values.cpostal}
                className="w-full py-1 border-b-2 text-gray-600 px-2 text-base font-medium placeholder:font-normal placeholder:text-sm outline-none focus-within:border-primeColor"
                type="text"
                placeholder="Código Postal"
              />
              {formik.errors.cpostal && formik.touched.cpostal && (
                <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                  <span className="font-bold italic mr-1">!</span>
                  {formik.errors.cpostal}
                </p>
              )}
            </div>
            <button
              type="submit"
              className="w-full bg-primeColor hover:bg-secondaryColor text-gray-200 hover:text-white cursor-pointer text-base font-medium h-10 rounded-md  duration-300"
            >
              Guardar cambios
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
