import React, { useState } from "react";
import { useFormik } from 'formik';
import { useAuthStore } from "../../hooks/useAuthStore";
import { BsCheckCircleFill } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { caneN } from "../../assets/images";

export const SignUp = () => {
  const { startRegister } = useAuthStore()
  const [checked, setChecked] = useState(false)

  const validate = (values) => {
    const errors = {};
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!values.name) {
      errors.name = 'El campo nombre es obligatorio';
    }
    if (!values.email) {
      errors.email = 'El campo email es obligatorio';
    } else if (!emailRegex.test(values.email)) {
      errors.email = 'El correo electrónico no es válido';
    }
    if (!values.password) {
      errors.password = 'El campo contraseña es obligatorio';
    }
    if (!values.telefono) {
      errors.telefono = 'El campo telefono es obligatorio';
    }
    if (!values.estado) {
      errors.estado = 'El campo estado es obligatorio';
    }
    if (!values.pais) {
      errors.pais = 'El campo pais es obligatorio';
    }
    if (!values.ciudad) {
      errors.ciudad = 'El campo ciudad es obligatorio';
    }
    if (!values.cpostal) {
      errors.cpostal = 'El campo cpostal es obligatorio';
    }
    if (!values.direccion) {
      errors.direccion = 'El campo direccion es obligatorio';
    }
    if (!values.colonia) {
      errors.colonia = 'El campo colonia es obligatorio';
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
      telefono: '',
      estado: '',
      pais: '',
      ciudad: '',
      cpostal: '',
      direccion: '',
      colonia: ''
    },
    validate,
    onSubmit: values => {
      registerSubmit(values)
    },
  });

  const registerSubmit = async (values) => {
    // const { name, email, password, telefono, estado, pais, ciudad, cpostal, direccion, colonia } = values
    startRegister({...values})
    // console.log({...values});
  }

  return (
    <div className="w-full h-screen flex items-center justify-start">
      <div className="w-1/2 hidden lgl:inline-flex h-full text-white">
        <div className="w-[450px] h-full bg-primeColor px-10 flex flex-col gap-6 justify-center">
          <Link to="/">
            <img src={caneN} alt="logoImg" className="w-16" />
          </Link>
          <div className="flex flex-col gap-1 -mt-1">
            <h1 className="font-titleFont text-xl font-medium">
              Comienza de forma gratuita
            </h1>
            <p className="text-base">Permanece conectado para obtener más información</p>
          </div>
          <div className="w-[300px] flex items-start gap-3">
            <span className="text-black mt-1">
              <BsCheckCircleFill />
            </span>
            <p className="text-base text-gray-300">
              <span className="text-white font-semibold font-titleFont">
                Empieza rápidamente con CANE
              </span>
              <br />
              Descubre la forma más fácil de comenzar a disfrutar de nuestras prendas de moda y accesorios exclusivos.
            </p>
          </div>
          <div className="w-[300px] flex items-start gap-3">
            <span className="text-black mt-1">
              <BsCheckCircleFill />
            </span>
            <p className="text-base text-gray-300">
              <span className="text-white font-semibold font-titleFont">
                Accede a todos los servicios de CANE
              </span>
              <br />
              En CANE, no solo encontrarás una amplia selección de ropa de alta calidad, sino que también tendrás acceso a una variedad de servicios exclusivos.
            </p>
          </div>
          <div className="w-[300px] flex items-start gap-3">
            <span className="text-black mt-1">
              <BsCheckCircleFill />
            </span>
            <p className="text-base text-gray-300">
              <span className="text-white font-semibold font-titleFont">
                Confiable para compradores en línea
              </span>
              <br />
              ¡Compra con tranquilidad y disfruta de la mejor moda en línea!
            </p>
          </div>
          <div className="flex items-center justify-between mt-10">
            <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
              © CANE
            </p>
            <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
              Terms
            </p>
            <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
              Privacy
            </p>
            <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
              Security
            </p>
          </div>
        </div>
      </div>
      <div className="w-full lgl:w-[500px] h-full flex flex-col justify-center">
          <form onSubmit={formik.handleSubmit} className="w-full lgl:w-[500px] h-screen flex items-center justify-center">
            <div className="px-6 py-4 w-full h-[96%] flex flex-col justify-start overflow-y-scroll scrollbar-thin scrollbar-thumb-primeColor">
              <h1 className="font-titleFont underline underline-offset-4 decoration-[1px] font-semibold text-2xl mdl:text-3xl mb-4">
                Regístrate aquí
              </h1>
              <div className="flex flex-col gap-3">
                {/* client name */}
                <div className="flex flex-col gap-.5">
                  <p className="font-titleFont text-base font-semibold text-gray-600">
                    Nombre completo
                  </p>
                  <input
                    name="name"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                    type="text"
                    placeholder="eg. John Doe"
                  />
                  {formik.errors.name && formik.touched.name && (
                    <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                      <span className="font-bold italic mr-1">!</span>
                      {formik.errors.name}
                    </p>
                  )}
                </div>
                {/* Email */}
                <div className="flex flex-col gap-.5">
                  <p className="font-titleFont text-base font-semibold text-gray-600">
                    Email
                  </p>
                  <input
                    name="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                    type="email"
                    placeholder="ornelas@workemail.com"
                  />
                  {formik.errors.email && formik.touched.email && (
                    <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                      <span className="font-bold italic mr-1">!</span>
                      {formik.errors.email}
                    </p>
                  )}
                </div>
                {/* Phone Number */}
                <div className="flex flex-col gap-.5">
                  <p className="font-titleFont text-base font-semibold text-gray-600">
                    Número de teléfono
                  </p>
                  <input
                    name="telefono"
                    onChange={formik.handleChange}
                    value={formik.values.telefono}
                    className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                    type="text"
                    placeholder="+52 624 147 70 77"
                  />
                  {formik.errors.telefono && formik.touched.telefono && (
                    <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                      <span className="font-bold italic mr-1">!</span>
                      {formik.errors.telefono}
                    </p>
                  )}
                </div>
                {/* Password */}
                <div className="flex flex-col gap-.5">
                  <p className="font-titleFont text-base font-semibold text-gray-600">
                    Contraseña
                  </p>
                  <input
                    name="password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                    type="password"
                    placeholder="Crea una contraseña segura"
                  />
                  {formik.errors.password && formik.touched.telefono && (
                    <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                      <span className="font-bold italic mr-1">!</span>
                      {formik.errors.password}
                    </p>
                  )}
                </div>
                {/* Address */}
                <div className="flex flex-col gap-.5">
                  <p className="font-titleFont text-base font-semibold text-gray-600">
                    Dirección
                  </p>
                  <input
                    name="direccion"
                    onChange={formik.handleChange}
                    value={formik.values.direccion}
                    className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                    type="text"
                    placeholder="Calle y número"
                  />
                  {formik.errors.direccion && formik.touched.direccion && (
                    <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                      <span className="font-bold italic mr-1">!</span>
                      {formik.errors.direccion}
                    </p>
                  )}
                </div>
                {/* Neighborhood */}
                <div className="flex flex-col gap-.5">
                  <p className="font-titleFont text-base font-semibold text-gray-600">
                    Colonia
                  </p>
                  <input
                    name="colonia"
                    onChange={formik.handleChange}
                    value={formik.values.colonia}
                    className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                    type="text"
                    placeholder="Colonia"
                  />
                  {formik.errors.colonia && formik.touched.colonia && (
                    <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                      <span className="font-bold italic mr-1">!</span>
                      {formik.errors.colonia}
                    </p>
                  )}
                </div>
                {/* City */}
                <div className="flex flex-col gap-.5">
                  <p className="font-titleFont text-base font-semibold text-gray-600">
                    Ciudad
                  </p>
                  <input
                    name="ciudad"
                    onChange={formik.handleChange}
                    value={formik.values.ciudad}
                    className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                    type="text"
                    placeholder="Ciudad"
                  />
                  {formik.errors.ciudad && formik.touched.ciudad && (
                    <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                      <span className="font-bold italic mr-1">!</span>
                      {formik.errors.ciudad}
                    </p>
                  )}
                </div>
                {/* State */}
                <div className="flex flex-col gap-.5">
                  <p className="font-titleFont text-base font-semibold text-gray-600">
                    Estado
                  </p>
                  <input
                    name="estado"
                    onChange={formik.handleChange}
                    value={formik.values.estado}
                    className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                    type="text"
                    placeholder="Estado"
                  />
                  {formik.errors.estado && formik.touched.estado && (
                    <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                      <span className="font-bold italic mr-1">!</span>
                      {formik.errors.estado}
                    </p>
                  )}
                </div>
                {/* Country */}
                <div className="flex flex-col gap-.5">
                  <p className="font-titleFont text-base font-semibold text-gray-600">
                    País
                  </p>
                  <input
                    name="pais"
                    onChange={formik.handleChange}
                    value={formik.values.pais}
                    className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                    type="text"
                    placeholder="País"
                  />
                  {formik.errors.pais && formik.touched.pais && (
                    <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                      <span className="font-bold italic mr-1">!</span>
                      {formik.errors.pais}
                    </p>
                  )}
                </div>
                {/* Zip code */}
                <div className="flex flex-col gap-.5">
                  <p className="font-titleFont text-base font-semibold text-gray-600">
                    C. Postal
                  </p>
                  <input
                    name="cpostal"
                    onChange={formik.handleChange}
                    value={formik.values.cpostal}
                    className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                    type="text"
                    placeholder="Código Postal"
                  />
                  {formik.errors.cpostal && formik.touched.cpostal && (
                    <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                      <span className="font-bold italic mr-1">!</span>
                      {formik.errors.cpostal}
                    </p>
                  )}
                </div>
                {/* Checkbox */}
                <div className="flex items-start mdl:items-center gap-2">
                  <input
                    name="checked"
                    onChange={() => setChecked(!checked)}
                    className="w-4 h-4 mt-1 mdl:mt-0 cursor-pointer"
                    type="checkbox"
                  />
                  <p className="text-sm text-primeColor">
                    Estoy de acuerdo con los{" "}
                    <span className="text-gray-500"> Terminos y condiciones </span>y la{" "}
                    <span className="text-gray-500"> Política de privacidad</span>.
                  </p>
                </div>
                <button
                  type="submit"
                  className={`${
                    checked
                      ? "bg-primeColor hover:bg-secondaryColor hover:text-white cursor-pointer"
                      : "bg-gray-500 hover:bg-gray-500 hover:text-gray-200 cursor-none"
                  } w-full text-gray-200 text-base font-medium h-10 rounded-md hover:text-white duration-300`}
                  disabled={!checked}
                >
                  Crear cuenta
                </button>
                <p className="text-sm text-center font-titleFont font-medium">
                  ¿Ya tienes una cuenta?{" "}
                  <Link to="/signin">
                    <span className="hover:text-blue-600 duration-300">
                      Inicia sesión aquí
                    </span>
                  </Link>
                </p>
              </div>
            </div>
          </form>
      </div>
    </div>
  );
};
